import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button";
import TopicsIcon from "@material-ui/icons/Spellcheck";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import useNotification from "utils/hooks/useNotification";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TextEditor from "components/TextEditor";
import Muted from "components/Typography/Muted";
import stylesAdmin from "./styles.module.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { v4 } from "uuid";
import {
  TOPIC_TYPE_ARGUMENTATIVE,
  TOPIC_TYPE_EXPOSITIVE,
  TOPIC_TYPE_JURIDICAL
} from "constants.js";

import { FiUpload } from "react-icons/fi";

import * as S from "./TopicModalStyles.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

const EditTopicModal = ({ open, handleClose, topic, onTopicUpdate, tags }) => {
  const classes = useStyles();

  const [commentedTopicPDF, setCommentedTopicPDF] = useState(null);
  const [essayModelPDF, setEssayModelPDF] = useState(null);

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: topic?.name,
      number: topic?.number,
      supportText: topic?.supportText,
      type: topic?.type,
      tagsIds: topic?.tagsIds ?? []
    },
    enableReinitialize: true,
    onSubmit: async ({ name, number, supportText, type, tagsIds }) => {
      try {
        setLoading(true);

        await onTopicUpdate(
          topic._id,
          name,
          number,
          supportText,
          type,
          commentedTopicPDF,
          essayModelPDF,
          tagsIds
        );

        setNotificationSuccess("Tema editado com sucesso");
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        setNotificationError(err.data.ErrorMessage);
        throw err;
      }
    }
  });

  const handleSupportTextChange = (text) => {
    formik.setFieldValue("supportText", text);
  };

  const isDisabled = formik.values.name === "" || formik.values.number === 0;

  const handleTagIdChange = (e) => {
    e.preventDefault();
    if (!formik.values.tagsIds.includes(e.target.value))
      formik.setFieldValue("tagsIds", [
        ...formik.values.tagsIds,
        e.target.value
      ]);
  };

  useEffect(() => {
    setCommentedTopicPDF(null);
    setEssayModelPDF(null);
  }, [setCommentedTopicPDF, setEssayModelPDF, topic]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit} className={stylesAdmin.submitForm}>
        <DialogContent>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <TopicsIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Editando Tema</h4>
            </CardHeader>
            <CardBody className={stylesAdmin.addContentDialog}>
              {/* TOPIC NUMBER */}
              <CustomInput
                labelText="Número do Tema"
                id="number"
                name="number"
                color="primary"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: formik.handleChange,
                  type: "number",
                  style: { width: "150px" },
                  value: formik.values.number
                }}
              />

              {/* TOPIC NAME */}
              <CustomInput
                labelText="Nome do Tema"
                id="name"
                name="name"
                color="primary"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: formik.handleChange,
                  value: formik.values.name
                }}
              />
              <br />
              <br />

              {/* SUPPORT TEXT */}
              <div className={styles.editorWrapper}>
                <Muted>Texto motivacional</Muted>
                <TextEditor
                  className={styles.editor}
                  initialText={formik.values.supportText}
                  onChange={handleSupportTextChange}
                />
              </div>
              <S.TagsWrapper>
                {formik?.values?.tagsIds?.length == 0 ? (
                  <label>Adicione tags ao tema</label>
                ) : (
                  formik?.values?.tagsIds?.map((id) => {
                    return (
                      <button
                        onClick={() => {
                          formik.setFieldValue(
                            "tagsIds",
                            formik.values.tagsIds.filter((_id) => _id !== id)
                          );
                        }}
                        key={v4()}>
                        {tags?.find((tag) => tag._id === id).name}
                        <span>x</span>
                      </button>
                    );
                  })
                )}
              </S.TagsWrapper>

              <Select
                id="tagsIds"
                name="tagsIds"
                onChange={handleTagIdChange}
                style={{ width: "100px", inputColor: "#9d36b3" }}
                inputProps={{
                  color: "#9d36b3",
                  value: formik.values.tagsIds
                }}>
                {tags.map((tags) => (
                  <MenuItem key={tags._id} value={tags._id}>
                    {tags.name}
                  </MenuItem>
                ))}
              </Select>

              <S.EditButtonsContainer>
                <S.UploadButton isUploaded={!!commentedTopicPDF}>
                  <FiUpload />
                  <input
                    id="tema-comentado-upload-edit"
                    type="file"
                    accept="application/pdf"
                    name="commentedTopicPDF"
                    onChange={(e) => {
                      setCommentedTopicPDF(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="tema-comentado-upload-edit">
                    Tema Comentado (pdf)
                  </label>
                </S.UploadButton>
                <S.UploadButton isUploaded={!!essayModelPDF}>
                  <FiUpload />
                  <input
                    id="modelo-redacao-upload-edit"
                    type="file"
                    accept="application/pdf"
                    name="essayModelPDF"
                    onChange={(e) => {
                      setEssayModelPDF(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="modelo-redacao-upload-edit">
                    Modelo de Redação (pdf)
                  </label>
                </S.UploadButton>

                <S.EditTopicContainer>
                  <S.RatioWrapper>
                    <input
                      id="argumentative"
                      type="radio"
                      name="type"
                      value={TOPIC_TYPE_ARGUMENTATIVE}
                      onChange={formik.handleChange}
                      defaultChecked={topic?.type === TOPIC_TYPE_ARGUMENTATIVE}
                    />
                    <label htmlFor="argumentative">Argumentativo</label>
                  </S.RatioWrapper>
                  <S.RatioWrapper>
                    <input
                      id="expositive"
                      type="radio"
                      name="type"
                      value={TOPIC_TYPE_EXPOSITIVE}
                      onChange={formik.handleChange}
                      defaultChecked={topic?.type === TOPIC_TYPE_EXPOSITIVE}
                    />
                    <label htmlFor="expositive">Expositivo - CEBRASPE</label>
                  </S.RatioWrapper>
                  <S.RatioWrapper>
                    <input
                      id="juridical"
                      type="radio"
                      name="type"
                      value={TOPIC_TYPE_JURIDICAL}
                      onChange={formik.handleChange}
                      defaultChecked={topic?.type === TOPIC_TYPE_JURIDICAL}
                    />
                    <label htmlFor="juridical">Jurídico</label>
                  </S.RatioWrapper>
                </S.EditTopicContainer>
              </S.EditButtonsContainer>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text" color="danger">
            Cancelar
          </Button>
          <Button
            onClick={formik.handleSubmit}
            disabled={isDisabled}
            color="primary">
            {!loading ? (
              "Editar"
            ) : (
              <CircularProgress size={20} color="primary" />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditTopicModal;
