import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from "@material-ui/icons/Send";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FileInput from "components/Files/Input";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardIcon,
  CardHeader,
  CardTitle
} from "components/Card";
import {
  POLICIA_CIVIL_CEARA,
  PROJETO_CAVEIRA_PC_CEARA,
  POLICIA_CIVIL_AMAZONAS,
  POLICIA_CIVIL_BAHIA
} from "../../constants.js";
import { useFormik } from "formik";
import { useEssaySubmit } from "./hooks";
import { useContests } from "views/Admin/hooks";
import useNotification from "utils/hooks/useNotification";
import styles from "./styles.module.css";
import Instructions from "./Instructions";
import { MD5 } from "crypto-js";
import ImageEditor, { editImage } from "components/ImageEditor/Editor";

const EssaySubmit = ({ isSimulation }) => {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [fileHash, setFileHash] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState(false);

  const { onSubmitEssay, user } = useEssaySubmit();
  const {
    contests: regularContests,
    contestsSimulations,
    contestsById
  } = useContests();

  const contests = isSimulation ? contestsSimulations : regularContests;

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const handleFileInputChange = async (e) => {
    await editImage(e.target.files[0], handleChange);
  };

  const handleChange = (newFile) => {
    setFile(newFile);
    setError(false);
  };

  const handleFileEdit = (file) => {
    setFile(file);
    setError(false);
  };

  const platform = useMemo(() => history.location.pathname.split("/")[1], [
    history.location.pathname
  ]);

  const handleSubmit = async ({ contestid, topic }) => {
    if (!file) return; // TODO: validate file

    if (file.size > 5000000) {
      setNotificationError("Tamanho do arquivo inválido. Máximo 5MB");
      return;
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setNotificationError(
        "Formato do arquivo inválido. Envie imagens .png ou .jpeg"
      );
      return;
    }
    if (error) {
      setNotificationError(error.toString());
      return;
    }

    try {
      setLoading(true);

      await onSubmitEssay(
        file,
        user._id,
        contestsById[contestid]._id,
        topic,
        fileHash
      );

      setLoading(false);

      setNotificationSuccess("Redação enviada com sucesso");

      setFile(null);

      formik.resetForm();

      history.push(`/${platform}/redacoes`);
    } catch (err) {
      setLoading(false);
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  const formik = useFormik({
    initialValues: {
      contestid: "",
      topic: ""
    },
    onSubmit: handleSubmit
  });

  const handleContestChange = (e) => {
    formik.setFieldValue("contestid", e.target.value._id);
    setTopics(e.target.value.topics);
  };
  const handleTopicChange = (e) => {
    e.preventDefault();
    formik.setFieldValue("topic", e.target.value);
  };
  const handleImageError = () => {
    const errMsg =
      "Formato de redação inválido. Por favor, envie um formato válido (PNG, JPG ou JPEG).";
    setNotificationError(errMsg);
    setError(new Error(errMsg));
  };

  function VerifySpecialClass(name, user) {
    const specialClasses = [
      POLICIA_CIVIL_CEARA,
      PROJETO_CAVEIRA_PC_CEARA,
      POLICIA_CIVIL_AMAZONAS,
      POLICIA_CIVIL_BAHIA
    ];

    if ((specialClasses.includes(name)) && user?.specialClass)
      return true;

    else if (!specialClasses.includes(name) && !user?.specialClass)
      return true;

    else if (user.type === 'admin')
      return true;

    return false;
  }

  const canSubmit = file !== null && formik.values.contestid !== '' && formik.values.topic !== '';

  useEffect(
    function handleWatchFileChanges() {
      // create the preview
      if (!file) {
        setPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    },
    [file]
  );

  return (
    <GridContainer>
      {/* <GridItem>{!readOnly && <Timer />}</GridItem> */}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SendIcon />
            </CardIcon>
            <CardTitle
              title={
                !isSimulation
                  ? "Enviar Redação"
                  : "Enviar Simulado - 14h as 22h"
              }
            />
          </CardHeader>
          <CardBody>
            <GridContainer justify="">
              <GridItem xs={12} md={8} lg={8}>
                <form onSubmit={formik.handleSubmit}>
                  <FileInput
                    fileName={file && file.name}
                    name="file"
                    onChange={handleFileInputChange}
                    className={styles.fileInput}
                    acceptedFiles={"image/png,image/jpeg"}
                  />
                  {file && (
                    <>
                      <img
                        id="essay-submit-preview"
                        src={preview}
                        onError={handleImageError}
                        className={styles.essayPreview}
                        alt="img"
                      />
                      <ImageEditor file={file} onEdit={handleFileEdit} />
                    </>
                  )}
                  <FormControl className={styles.field}>
                    <InputLabel id="contestid">
                      {!isSimulation ? "Concurso" : "Simulado"}
                    </InputLabel>
                    <Select
                      id="contestid"
                      name="contestid"
                      onChange={handleContestChange}
                      value={formik.values.contest}
                      style={{ inputColor: "#9d36b3" }}
                      inputProps={{
                        fullWidth: true
                      }}>
                      {contests
                        .filter((c) =>
                          isSimulation ? c.isSimulation : !c.isSimulation
                        )
                        .map(
                          (c, key) =>
                            VerifySpecialClass(c.name, user) && (
                              <MenuItem key={key} value={c}>
                                {c.name}/{c.institution?.name}
                              </MenuItem>
                            )
                        )}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.field}>
                    <InputLabel id="topic">Tema</InputLabel>
                    <Select
                      id="topic"
                      name="topic"
                      onChange={handleTopicChange}
                      value={formik.values.topic}
                      style={{ inputColor: "#9d36b3" }}
                      inputProps={{
                        fullWidth: true
                      }}>
                      {topics.map((t, index) => (
                        <MenuItem key={index} value={t._id}>
                          Tema {t.number}: {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    type="submit"
                    disabled={!canSubmit}
                    style={{
                      minWidth: "300px",
                      width: "100%",
                      inputColor: "#9d36b3",
                      marginTop: "3rem"
                    }}
                    color={canSubmit ? "info" : ""}
                  >

                    {!loading ? "Enviar" : <CircularProgress color="primary" />}
                  </Button>
                </form>
              </GridItem>
              <GridItem xs={12} md={4} lg={4}>
                <Instructions />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withRouter(EssaySubmit);
