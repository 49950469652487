import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

// TODO: Use own styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const ReviewStatsCard = ({
  color,
  deadlineStatus,
  amount,
  icon,
  itemWidth = 4,
  underText = "",
  isButton = false,
  onClick = () => {}
}) => {
  const classes = useStyles();
  return (
    <GridItem xs={10} sm={6} md={4} lg={itemWidth}>
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>{icon}</CardIcon>
          <p className={classes.cardCategory}>{deadlineStatus}</p>
          <h3 className={classes.cardTitle}>{amount}</h3>
        </CardHeader>
        <CardFooter stats>
          {isButton ? (
            <button className={classes.statsButton} onClick={onClick}>
              {underText}
            </button>
          ) : (
            <div className={classes.stats}>{underText}</div>
          )}
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default ReviewStatsCard;
