import { useDispatch, useSelector } from "react-redux";
import * as authActions from "redux/actions/auth";
import * as institutionsActions from "redux/actions/institutions";
import * as contestsActions from "redux/actions/contests";
import * as topicsActions from "redux/actions/topics";
import * as reviewsActions from "redux/actions/reviews";
import * as usersActions from "redux/actions/users";
import * as usersSelector from "redux/selectors/users";
import * as essaysSelector from "redux/selectors/essays";
import * as institutionsSelector from "redux/selectors/institutions";
import * as contestsSelector from "redux/selectors/contests";
import * as topicsSelector from "redux/selectors/topics";
import { useFetchOnce } from "utils/hooks/useFetchMachine";

export function useRegister() {
  const dispatch = useDispatch();
  const onRegister = (name, email, password, type, credits) =>
    dispatch(authActions.onRegister(name, email, password, type, credits));

  return {
    onRegister
  };
}

export function useUsers() {
  const dispatch = useDispatch();
  const users = useSelector(usersSelector.users);
  const usersById = useSelector(usersSelector.usersById);
  const { stats } = useAdminStats();
  const onUsers = () => dispatch(usersActions.onUsers());

  const isUserListComplete = stats?.users === users.length;
  const { loading, error } = useFetchOnce(onUsers, [], !isUserListComplete);

  const onUserUpdate = (
    id,
    name,
    email,
    password,
    type,
    credits,
    specialClass,
    ignoreWeeklyCreditsExpiration,
    creditsExpiresAt
  ) =>
    dispatch(
      usersActions.onAdminUserUpdate(
        id,
        name,
        email,
        password,
        type,
        credits,
        specialClass,
        ignoreWeeklyCreditsExpiration,
        creditsExpiresAt
      )
    );

  const onUserDelete = (id) => dispatch(usersActions.onUserDelete(id));

  return {
    users,
    usersById,
    loading,
    error,
    onUsers,
    onUserUpdate,
    onUserDelete,
    stats
  };
}

export function useUserDetails(userid) {
  const dispatch = useDispatch();
  const user = useSelector(usersSelector.userById(userid));
  const essays = useSelector(essaysSelector.essaysByUserId(userid));
  const onFetchUserDetails = (userid) =>
    dispatch(usersActions.onFetchUserDetails(userid));
  const { loading, error } = useFetchOnce(onFetchUserDetails, [userid]);
  const onUpdateUserPassword = (password) =>
    dispatch(usersActions.onAdminUserPasswordChange(userid, password));
  const onStatusChange = (id, status) =>
    dispatch(reviewsActions.onReviewStatusChange(id, status));
  return {
    user,
    loading,
    error,
    essays,
    onStatusChange,
    onFetchUserDetails,
    onUpdateUserPassword
  };
}

export function useInstitutions() {
  const dispatch = useDispatch();
  const institutions = useSelector(institutionsSelector.institutions);
  const institutionsById = useSelector(institutionsSelector.institutionsById);

  const onFetchInstitutions = () =>
    dispatch(institutionsActions.onFetchInstitutions());

  const { loading, error } = useFetchOnce(onFetchInstitutions, []);

  const onInstitutionNew = (name) =>
    dispatch(institutionsActions.onInstitutionNew(name));

  const onInstitutionUpdate = (institutionid, name) =>
    dispatch(institutionsActions.onInstitutionUpdate(institutionid, name));

  const onInstitutionDelete = (institutionid) =>
    dispatch(institutionsActions.onInstitutionDelete(institutionid));

  return {
    institutions,
    institutionsById,
    loading,
    error,
    onInstitutionNew,
    onInstitutionUpdate,
    onInstitutionDelete
  };
}

export function useContests() {
  const dispatch = useDispatch();
  const contests = useSelector(contestsSelector.contests);
  const contestsById = useSelector(contestsSelector.contestsById);
  const contestsSimulations = useSelector(contestsSelector.simulations);

  const onFetchContests = () => dispatch(contestsActions.onFetchContests());
  const onFetchContestsSimulations = () =>
    dispatch(contestsActions.onFetchContestsSimulations());
  const { loading, error } = useFetchOnce(onFetchContests, []);
  const { loading: loadingContests, error: errorContests } = useFetchOnce(
    onFetchContestsSimulations,
    []
  );

  const onContestNew = (name, institution, topics, date) =>
    dispatch(contestsActions.onContestNew(name, institution, topics, date));

  const onContestUpdate = (contestid, name, institutionid, topics, date) =>
    dispatch(
      contestsActions.onContestUpdate(
        contestid,
        name,
        institutionid,
        topics,
        date
      )
    );

  const onContestDelete = (contestid) =>
    dispatch(contestsActions.onContestDelete(contestid));

  return {
    contests,
    contestsById,
    loading,
    error,
    onContestNew,
    onContestUpdate,
    onContestDelete,
    contestsSimulations,
    loadingContests,
    errorContests
  };
}

export function useTopics() {
  const dispatch = useDispatch();
  const topics = useSelector(topicsSelector.topics);
  const topicsById = useSelector(topicsSelector.topicsById);

  const onFetchTopics = () => dispatch(topicsActions.onFetchTopics());

  const { loading, error } = useFetchOnce(onFetchTopics, []);

  const onTopicNew = (
    name,
    number,
    supportText,
    type,
    commentedTopicPDF,
    essayModelPDF,
    tagsIds
  ) =>
    dispatch(
      topicsActions.onTopicNew(
        name,
        number,
        supportText,
        type,
        commentedTopicPDF,
        essayModelPDF,
        tagsIds
      )
    );

  const onTopicUpdate = (
    topicid,
    name,
    number,
    supportText,
    type,
    commentedTopicPDF,
    essayModelPDF,
    tagsIds
  ) =>
    dispatch(
      topicsActions.onTopicUpdate(
        topicid,
        name,
        number,
        supportText,
        type,
        commentedTopicPDF,
        essayModelPDF,
        tagsIds
      )
    );

  const onTopicDelete = (topicid) =>
    dispatch(topicsActions.onTopicDelete(topicid));

  return {
    topics,
    topicsById,
    loading,
    error,
    onTopicNew,
    onTopicUpdate,
    onTopicDelete
  };
}

export function useAdminStats() {
  const dispatch = useDispatch();
  const stats = useSelector(usersSelector.userAdminStats);
  const onFetchUserAdminStats = () =>
    dispatch(usersActions.onFetchUserAdminStats());
  const { loading, error } = useFetchOnce(onFetchUserAdminStats, []);

  return {
    stats,
    loading,
    error
  };
}
