import React from "react";
import { Card, CardBody, CardHeader } from "components/Card";
import Muted from "components/Typography/Muted.js";
import styles from "./styles.module.css";

const Instructions = () => (
  <Card className={styles.cardInfo}>
    <CardHeader>
      <h4 className={styles.cardTitle}>Instruções</h4>
      <p className={styles.cardCategory}>Como enviar sua redação:</p>
    </CardHeader>
    <CardBody>
      <p>
        <Muted>
          1. Após ter escolhido um tema, lido o texto motivacional e escrito sua
          redação, anexe a imagem da mesma
        </Muted>
      </p>
      <p>
        <Muted>
          2. A imagem deve estar em formato <b>.png</b> ou <b>.jpeg</b> e até{" "}
          <b>4MB</b>
        </Muted>
      </p>
      <p>
        <Muted>
          3. Caso só tenha o arquivo em outro formato, utilize um conversor
        </Muted>
      </p>
      <p>
        <Muted>4. Verifique se a imagem está legível</Muted>
      </p>
      <p>
        <Muted>
          5. Ajuste a rotação da imagem se for preciso
        </Muted>
      </p>
      <p>
        <Muted>6. Escolha o concurso e o tema da sua redação</Muted>
      </p>
      <p>
        <Muted>7. Envie a redação e aguarde</Muted>
      </p>
      <br />
      <p className={styles.cardCategory}>
        Certifique-se de que sua conta possui créditos para o envio de redações.
      </p>
    </CardBody>
  </Card>
);
export default Instructions;
