import styled, { keyframes } from "styled-components";

export const PlayerControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  width: auto;
`;

export const EnableRecordButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  svg {
    fill: white;
  }
`;

export const DisableRecordButton = styled.button`
  animation: none;

  background-color: rgb(0, 0, 0, 0);

  width: auto;
  height: auto;

  cursor: pointer;
  border: none;
  outline: none;

  padding: 0;

  svg {
    fill: ${(props) => (props.isPaused ? "white" : "red")};
  }
`;

export const PauseAndResumeRecordButton = styled(EnableRecordButton)`
  background-color: #444;
  animation: none;

  margin-left: 10px;

  height: 46px;
  width: 46px;

  svg {
    fill: white;
  }
`;

export const Timer = styled.div`
  color: ${(props) => (props.isPaused ? "white" : "red")};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  visibility: ${(props) => (props.isRecording ? "visible" : "hidden")};
  opacity: ${(props) => (props.isRecording ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
`;

export const StateAndCounter = styled.div`
  background-color: #444;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 5px;

  padding: 12px;

  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;

  height: 46px;
  width: auto;
`;
